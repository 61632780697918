var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.selectNewImageAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["selectNewImageAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "selectNewImageAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        { staticClass: "scrollable list-group-accent mt-5" },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "mb-0 list-group-item-accent-primary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" SELECT NEW IMAGE ")]
          ),
          _c(
            "CListGroupItem",
            { staticClass: "list-group-item-accent-secondary" },
            [
              _c("v-select", {
                staticClass: "v-select-filter",
                attrs: {
                  placeholder: "Tags..",
                  options: _vm.tagList,
                  reduce: (t) => t.id,
                  label: "name",
                  multiple: "",
                  searchable: false,
                },
                on: { input: _vm.getAllItems },
                model: {
                  value: _vm.tags,
                  callback: function ($$v) {
                    _vm.tags = $$v
                  },
                  expression: "tags",
                },
              }),
              _c("div", { staticClass: "image-container" }, [
                _c(
                  "ul",
                  { staticClass: "image-list" },
                  _vm._l(_vm.loadedItems, function (image) {
                    return _c(
                      "li",
                      {
                        key: image.id,
                        class: {
                          selected:
                            image === _vm.selectedImage ||
                            (!_vm.selectedImage && image.id == _vm.imageId),
                        },
                        on: {
                          click: function ($event) {
                            _vm.selectedImage = image
                          },
                        },
                      },
                      [_c("img", { attrs: { src: image.image_url } })]
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "sticky-bottom list-group-item-accent-primary bg-white",
            },
            [
              _c(
                "CButton",
                {
                  attrs: { color: "primary", disabled: !_vm.selectedImage },
                  on: { click: _vm.select },
                },
                [_vm._v(" Select ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }